const mapWrapper = document.querySelector('.map-wrapper')

const mapIframe = `
  <iframe
  class="aspect-[33/26] md:max-w-[40%] xl:aspect-[57/35] xl:w-[570px] xl:flex-shrink-0 xl:max-w-[unset]"
  src="https://yandex.ru/map-widget/v1/?um=constructor%3A18ae18935f604182c6fd058094fbb329ae3cd43a38cfc4c32728ed914b2964f5&amp;source=constructor"
  width="100%"
  height="about"
  frameborder="0"
  ></iframe>
`

//  insert iframe into mapWrapper when mapWrapper is in viewport

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      mapWrapper.innerHTML = mapIframe
      observer.disconnect()
      mapWrapper.classList.remove('animate-pulse')
    }
  })
})

observer.observe(mapWrapper)
